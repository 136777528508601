import React from 'react'

import TopPage from '../../components/_organisms/TopPage'
import FormLoja from '../../components/_templates/FormLoja'
import FormSenha from '../../components/_templates/FormSenha'
import FormDelivery from '../../components/_templates/FormDelivery'
import FormPlano from '../../components/_templates/FormPlano'
import FormImpressao from '../../components/_templates/FormImpressao'
import FormFiscal from '../../components/_templates/FormFiscal'


export default class EditarNegocio extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
            model:this.props.info.match.params.model,
            tabActive: "tab-1",
        }

        this.handleTabs = this.handleTabs.bind(this)
    }

 
    handleTabs(e){
        e.preventDefault()

        this.setState({tabActive: e.target.dataset.tab})
    }

    render() {
        return (
            <div>
                <TopPage model={this.state.model} new={true}>
                </TopPage>

                <p className="lead">
                    Domine seu ambiente operacional! Em nossas configurações avançadas, você personaliza cada detalhe do seu espaço. Estabeleça políticas que se alinham à sua visão operacional. Acesse ações globais instantaneamente: aceite pedidos, habilite/desabilite o delivery e muito mais. Sua operação, suas regras. Simplificamos o controle para que você direcione sua operação com eficiência e agilidade.
                </p>

                <ul className="nav nav-tabs">
                    <li className="nav-item">
                    <a href="#tab-1" data-tab="tab-1" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-1" ? "nav-link active" : "nav-link"}>
                            CONFIGURAÇÕES GERAIS
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-2" data-tab="tab-2" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-2" ? "nav-link active" : "nav-link"}>
                            SEGURANÇA
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-3" data-tab="tab-3" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-3" ? "nav-link active" : "nav-link"}>
                            OPERAÇÃO
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-4" data-tab="tab-4" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-4" ? "nav-link active" : "nav-link"}>
                            PLANO
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-5" data-tab="tab-5" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-5" ? "nav-link active" : "nav-link"}>
                            IMPRESSÃO
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-6" data-tab="tab-6" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-6" ? "nav-link active" : "nav-link"}>
                            FISCAL + MEU DINHEIRO
                        </a>
                    </li>
                </ul>

                <div className="tab-content panel p-5 rounded-0 rounded-bottom">
                    <div className={this.state.tabActive == "tab-1" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-1">
                        {this.state.user.loja && (
                            <FormLoja model={this.state.model} id={this.state.user.loja.id} endereco={this.state.user.loja.id_endereco}/>
                        )}
                    </div>

                    <div className={this.state.tabActive == "tab-2" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-2">
                        {this.state.user.loja && (
                            <FormSenha />
                        )}
                    </div>

                    <div className={this.state.tabActive == "tab-3" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-3">
                        {this.state.user.loja && (
                            <FormDelivery id={this.state.user.loja.id_endereco} />
                        )}
                    </div>

                    <div className={this.state.tabActive == "tab-4" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-4">
                        {this.state.user.loja && (
                            <FormPlano />
                        )}
                    </div>

                    <div className={this.state.tabActive == "tab-5" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-5">
                        {this.state.user.loja && (
                            <FormImpressao showSetor={true} />
                        )}
                    </div>

                    <div className={this.state.tabActive == "tab-6" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-6">
                        {this.state.user.loja && (
                            <FormFiscal model={this.state.model} id={this.state.user.loja.id} endereco={this.state.user.loja.id_endereco}/>
                        )}
                    </div>
                </div>

            </div>
        )
    }
}