import React from 'react'
import { Link } from 'react-router-dom'

import Offcanvas from 'react-bootstrap/Offcanvas'
import Detalhe from './DetalheCliente'

import Paginacao from '../paginacao/Paginacao.jsx'
import { getList } from '../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"

class ListaCliente extends React.Component {

	constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			brand: user.brand ? user.brand : "",
			loja: user.loja,
			model:null,
			lista:[],
			showDetalhe: false,
			clienteSelecionado: null,
		}

		this.fetchData = this.fetchData.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.handleCliente = this.handleCliente.bind(this)
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})
		}
	}
	
	handleCliente(cliente){
		this.setState({showDetalhe:true, clienteSelecionado:cliente})
	}

	componentDidMount() {
		this.fetchData()
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	getTotal(comandas){
		let total = parseFloat(0,0)

		if(comandas && comandas.length > 0){
			comandas.forEach(c => {
				if (c.meta.pivot_total) {
					total = parseFloat(total) + parseFloat(c.meta.pivot_total)
				}
			})
		} 
		
		return total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
	}

	ticketMedio(comandas){
		let total = parseFloat(0,0)
		let quantidade = 0

		if(comandas && comandas.length > 0){
			comandas.forEach(c => {
				if (c.meta.pivot_total) {
					total = parseFloat(total) + parseFloat(c.meta.pivot_total)
					quantidade++
				}
			})
		} 
		
		return total > 0 ? (total/quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
	}

	ultimoPedido(comandas){
		let result = "-"

		if(comandas && comandas.length > 0){
			if(comandas[comandas.length-1].meta.pivot_ultimo_pedido) {
				const dt = new Date(comandas[comandas.length-1].meta.pivot_ultimo_pedido)
				result = parseInt((new Date() - dt) / (1000 * 60 * 60 * 24))
			}
		} 
		
		return result
	}

	render() {
		const lista = this.state.lista
		return (
			<div>
				{lista && lista.data && lista.data.length > 0 ? (
					<div className="card border-0">
						
						<div className="tab-content p-3">
							
							<div className="tab-pane fade show active" id="allTab">
								
								<div className="table-responsive mb-3">
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th></th>
												<th>Nome</th>
												<th>Receita</th>
												<th>Ticket Médio</th>
												<th>Pedidos</th>
												<th>Último Pedido</th>
												<th>Telefone</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											{lista.data.length > 0 && lista.data.map((item) => 
												<tr key={"item_"+item.id}>
													<td className="w-10px align-middle">
														<img src='/imagem-perfil.png' style={{width: '45px', borderRadius: "5px"}} />
													</td>
													<td style={{textTransform:"uppercase", color:"#0043ff", cursor:"pointer"}}>
														<b onClick={() => this.handleCliente(item)}>{item.nome}</b>
													</td>
													{item.comandas.length > 0 ? (
														<td style={{color:"green"}}><b>{ this.getTotal(item.comandas) }</b></td>
													) : (
														<td>Apenas cadastro</td>
													)}

													{item.comandas.length > 0 ? (
														<td><b>{ this.ticketMedio(item.comandas) }</b></td>
													) : (
														<td>Sem dados</td>
													)}

													<td>{ item.comandas.length }</td>

													{item.comandas.length > 0 ? (
														<td>{this.ultimoPedido(item.comandas) > 0 ? "Faz "+this.ultimoPedido(item.comandas)+" dias" : "Hoje"}</td>
													) : (
														<td>Sem pedidos</td>
													)}
													<td>{item.telefone}</td>
													<td>
														<Link to={{pathname:"whatsapp://send?text= &phone=+55"+item.telefone.replace("(","").replace(")","").replace("-","").replace(" ","")}} className="btn btn-outline-success"  target="_blank"> 
														<i className="fa-brands fa-whatsapp"></i> Enviar whatsapp
														</Link>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>

							</div>
							{this.paginacao()}
						</div>

					</div>
				)
				:
				( 
					<div className="card border-0" style={{padding:"15px"}}>
						<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
						<h1 style={{textAlign:"center"}}>Nenhum cliente cadastrado por aqui! Cadastre um agora. </h1>
						<p  style={{textAlign:"center"}}></p>
					</div>
				)
				}

				{this.state.clienteSelecionado && (
					<Offcanvas show={this.state.showDetalhe} onHide={() => this.setState({showDetalhe: false})}  placement="end" style={{width:"40%"}}>
						<Offcanvas.Body>

							<Detalhe cliente={this.state.clienteSelecionado} />

						</Offcanvas.Body>
					</Offcanvas>
				)}
			</div>
		)
	}
}

export default ListaCliente;