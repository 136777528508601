import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'moment'

import Paginacao from '../../components/paginacao/Paginacao.jsx'
import { getList, getStructList, getBusca, geraNota } from './../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/pedidos-vazios-cozinha.json"

import Offcanvas from 'react-bootstrap/Offcanvas'

import Detalhe from './../../pages/detalhe.js'

class Lista extends React.Component {

	constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			user: user,
			model:null,
			detalheId:null,
			showCanvas:false,
			filterPayment:null,
			lista:[],
			struct:[],
			filtro: "todos",
			tabs: [{chave: 'todos', valor: 'TODOS'}, {chave: 'mesa', valor: 'MESA'}, {chave: 'delivery', valor: 'DELIVERY'}, {chave: 'dinein', valor: 'COMANDA'}]
		}

		this.aplicaFiltro = this.aplicaFiltro.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.handleBusca = this.handleBusca.bind(this)
	}

	async handleGeraNota(comandaId){
		await geraNota(this.state.user.loja.id, comandaId)
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getStructList(this.props.model).then(result => {
				this.setState({ struct: result })
			})

			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})
		}
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	componentDidMount() {
		this.fetchData()
	}

	validaPedidos(pedidos){
		let result = true

		pedidos.forEach(element => {
			if (element.status == "aberto") {
				result = false
			}
		})

		return result
	}

	aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
		const id = t.dataset.filter != 'todos' ? t.dataset.filter : ''

		getList(this.state.model, {filtro: id}).then(result => {
			this.setState({ lista: result })
		})
		this.setState({filtro: id === '' ? "todos" : id})
	}

	handleBusca(e){
		const valor = e.target.value 

		if (valor != ""){
			getBusca(this.state.model, {filtro:valor}).then(result => {
				this.setState({ lista: result })
			})
		} else {
			getList(this.state.model, {page:e.target.dataset.page}).then(result =>
				this.setState({ lista: result })
			)
		}

		
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	handleDetalhe(e, item){
		e.preventDefault()
		this.setState({detalheId:item.id, showCanvas:!this.state.showCanvas})
	}

	handlerFilterPayment(e, item){
		e.preventDefault()

		getList(this.state.model, {filtro:item}).then(result => {
			this.setState({ lista: result })
		})

		this.setState({filterPayment:item})
	}

	busca(event) {
		event.preventDefault()
		this.handleBusca(event)
	}

	downloadNotaXML(link, pedidoId){
		fetch(link)
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.target = '_blank';
				a.download = 'nota_'+pedidoId+'.xml'; // You can set desired filename here
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			});
	}

	downloadNotaPDF(link, pedidoId){
		fetch(link)
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.target = '_blank';
				a.download = 'nota_'+pedidoId+'.pdf'; // You can set desired filename here
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			});
	}

	sedToCofnig(){
		window.location.href = "/negocio/edit/Loja"
	}

	getIcon(pagamentos){
		const result = []
		const type = []
		
		for (let i in pagamentos) {
			if (!type.includes(pagamentos[i].tipo)){
			
				if (pagamentos[i].tipo === "credito") 	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#0078FF"}}></i> Crédito</span> )
				if (pagamentos[i].tipo === "pix")  		result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-brands fa-pix fs-9px fa-fw me-5px" style={{color:"#00BDAF"}}></i> PIX</span> )
				if (pagamentos[i].tipo === "dinheiro")  result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-money-bill fs-9px fa-fw me-5px" style={{color:"#0FC14A"}}></i> Dinheiro</span> )
				if (pagamentos[i].tipo === "debito")  	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#FF6F00"}}></i> Débito</span> )
				if (pagamentos[i].tipo === "vale")  	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-wallet fs-9px fa-fw me-5px" style={{color:"#0043ff"}}></i> Vale</span> )
				if (pagamentos[i].tipo === "outros")  	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-money-bill fs-9px fa-fw me-5px" style={{color:"#0043ff"}}></i> Outros</span> )
			}
			type.push(pagamentos[i].tipo)
		}

		return result
	}
	
	render() {
		const lista = this.state.lista
		return (
			<div>
				
				<div className="card border-0">
					<ul className="nav nav-tabs nav-tabs-v2 px-3">
						{this.state.tabs.map( (item) =>
							<li key={item.chave} className="nav-item me-2" >
								<Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
									{item.valor}
								</Link>
							</li>
						)}
					</ul>
					
					<div className="tab-content p-3">
						<div className="input-group mb-3">
							<button className="btn btn-white" type="button" data-bs-toggle="dropdown">
								<span className="d-none d-md-inline">Buscar pedidos</span>
							</button>
							
							<div className="flex-fill position-relative">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.busca(e)}/>
								</div>
							</div>
						</div>
					</div>

					{this.state.filtro == "todos" &&
						<div>
							<div className="btn-group mb-3"  style={{float:"left", marginLeft:"20px"}}>
								<button className={this.state.filterPayment === "credito" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "credito")}>
									<i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#0078FF"}}></i> Crédito
								</button>
								<button className={this.state.filterPayment === "pix" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "pix")}>
									<i className="fa-brands fa-pix fs-9px fa-fw me-5px" style={{color:"#00BDAF"}}></i> PIX
								</button>
								<button className={this.state.filterPayment === "dinheiro" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "dinheiro")}>
									<i className="fa-solid fa-money-bill fs-9px fa-fw me-5px" style={{color:"#0FC14A"}}></i> Dinheiro
								</button>
								<button className={this.state.filterPayment === "debito" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "debito")}>
									<i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#FF6F00"}}></i> Débito
								</button>
							</div>
						</div>
					}

					{lista.data && lista.data.length > 0 ? (
						<div className="tab-content p-3">
							<div className="tab-pane fade show active" id="allTab">
								
								<div className="table-responsive mb-3">
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th></th>
												<th>Pedido</th>
												<th>Data</th>
												<th>Tipo</th>
												<th>Mesa/Comanda</th>
												<th>Pagamento</th>
												<th>Itens</th>
												<th>Nota Fiscal</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											{lista.data.length > 0 && lista.data.map((item) => 
												<tr key={"item_"+item.id}>
													<td className="w-10px align-middle">
														<div className="form-check">
															<input type="checkbox" className="form-check-input" id="product1" />
															<label className="form-check-label" for="product1"></label>
														</div>
													</td>
													<td><Link to="#" onClick={(e) => this.handleDetalhe(e, item)} className="fw-bold">#{item.id}</Link></td>
													<td>{Moment(item.created_at).format('DD/MM/YYYY - HH:mm')}</td>
													<td>
														{item.b_delivery === 1 &&
															<span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> Delivery
															</span>
														}

														{item.b_takeaway === 1 &&
															<span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda 
															</span>
														}

														{item.b_delivery === 0 && item.b_takeaway === 0 && item.mesa.b_comanda === 0 &&
															<span className="badge border border-pink text-pink  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-utensils fs-9px fa-fw me-5px"></i> Mesa
															</span>
														}

														{item.b_delivery === 0 && item.b_takeaway === 0 && item.mesa.b_comanda === 1 &&
															<span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda
															</span>
														}
													</td>
													<td>{item.mesa ? item.mesa.identificador : item.id}</td>
													
													<td>
														{item.pagamentos &&  item.pagamentos.length > 0 &&
															this.getIcon(item.pagamentos)
														}
													</td>
													<td>{item.pedidos.length} itens</td>
													<td>
														{this.state.user.loja.b_notas ? (
															<>
																{item.xml_nota ? (
																	<>
																		<button className="btn btn-white" style={{color:"#0043ff"}} onClick={(e) => this.downloadNotaXML(item.xml_nota, item.id)}>
																			<i className="fa-solid fa-file-invoice"></i> Download Nota XML
																		</button>
																		<button className="btn btn-white" style={{color:"#0043ff"}} onClick={(e) => this.downloadNotaPDF(item.xml_danfe, item.id)}>
																			<i className="fa-solid fa-file-invoice"></i> Download Nota PDF
																		</button>
																	</>
																) : (
																	<button className="btn btn-white" style={{color:"#0043ff"}} onClick={(e) => this.handleGeraNota(item.id)}>
																		<i className="fa-solid fa-file-invoice"></i> Gerar Nota
																	</button>
																)}
															</>
														):(
															<button className="btn btn-white" style={{color:"#0043ff"}} onClick={(e) => this.sedToCofnig()}>
																<i className="fa-solid fa-file-invoice"></i> Configurar Fiscal
															</button>
														)}
													</td>
													{item.b_delivery === 1 && item.pagamentos && item.pagamentos.length > 0 ? (
														<td><b>R$ {item.pedidos.length && (item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ) + parseFloat(item.pagamentos[0].valor_taxa_entrega)).toFixed(2)}</b></td>
													) : (
														<>
															{item.pagamentos && item.pagamentos.length > 0 && item.pagamentos[0].b_taxa === 1 ? (
																<td><b>R$ {item.pedidos.length && (item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ) + (item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ) * 0.1)).toFixed(2)}</b></td>
															):(
																<td><b>R$ {item.pedidos.length && item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ).toFixed(2)}</b></td>
															)}
														</>
													)}
												</tr>
											)}
										</tbody>
									</table>
								</div>

								{this.props.paginacao && (this.paginacao())}
							</div>
						</div>
					)
					:
					( 
						<div className="card border-0" style={{padding:"15px"}}>
							<Lottie animationData={lottiejson} loop={true} style={{width: "450px", margin:"auto"}} />
							<h1 style={{textAlign:"center"}}>Nenhum pedido por aqui! Aproveite seu dia... </h1>
							<p  style={{textAlign:"center"}}>De momento não tem nenhum pedido no seu estabelecimento.</p>
						</div>
					)}
				</div>

				<Offcanvas show={this.state.showCanvas} onHide={() => this.setState({showCanvas: false})}  placement="end" style={{width:"40%"}}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>
							<h3>Pedido: #{this.state.detalheId}</h3>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Detalhe id={this.state.detalheId}/>
					</Offcanvas.Body>
				</Offcanvas>
				
			</div>
		)
	}
}

export default Lista;