import React from 'react'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import NVD3Chart from 'react-nvd3'
import d3 from 'd3'
import Chart from 'react-apexcharts'
import { getDash } from './../store/api.js'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Moment from 'moment'
import { AppSettings } from './../config/app-settings.js'

class Dashboard extends React.Component {
	static contextType = AppSettings

	constructor(props) {
      	super(props)
		const lsUser = localStorage.getItem('user')
		const user = lsUser ? JSON.parse(localStorage.getItem('user')).data : null

      	this.state = {
			user: user,
			brand:user ? user.brand : null,
			nome_fantasia:user ? user.nome_fantasia : null,
			isLoged: user ? true : false,
			dashData: null,
			areaChartData: [],
			top10Produtos: [],
			top10ProdutosValore: [],
			infoComparativoLabels: [],
			infoComparativo30Dias: [],
			infoComparativo60Dias: [],
			dateRange: null,
			modalConfigImpressora: false,
			filterDates: "today"
		}

		this.handleDateApplyEvent = this.handleDateApplyEvent.bind(this)
		this.hadleFilterDate = this.hadleFilterDate.bind(this)
	}

	getLast30Days(d = null) {
		const arr = new Array()
		const today = d ? new Date(d) : new Date()
		const priorDate = new Date(new Date().setDate(today.getDate() - 30))

		while (priorDate <= today) {
		  arr.push(new Date(priorDate))
		  priorDate.setDate(priorDate.getDate() + 1)
		}
	  
		return arr
	}

	fetchData(di = null, df = null){
		getDash(di, df).then(result => {
			const vendas30dias = []
			const last30days = this.getLast30Days(df)
			const infoComparativoLabels = new Array()
			const infoComparativo30Dias = new Array()
			const infoComparativo60Dias = new Array()
			const top10ProdutosValore = new Array()
			const top10Produtos = new Array()
			const top10vendas = new Array()	
			const top10vendasValor = new Array()	

			//last30days.forEach(item => {
				result.vendas30dias.forEach(ritem => {
					const d1 = new Date(ritem.created_at)
					//const d2 = new Date(item)

					//if (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()) {
						vendas30dias.push({ x: d1, y: parseInt(ritem.total) })
					//} 
				})
			//})

			result.vendas30dias.forEach(item => {
				const d1 = new Date(item.created_at)
				infoComparativo30Dias.push(item.total)
				infoComparativoLabels.push(d1.getDate())
			})

			result.vendas60dias.forEach(item => {
				infoComparativo60Dias.push(item.total)
			})

			result.top10Produtos.forEach(item => {
				top10Produtos.push(item.nome)
				top10ProdutosValore.push(item.total)
			})

			result.top10ProdutosPorValor.forEach(item => {
				top10vendas.push(item.nome)
				top10vendasValor.push(parseInt(item.total))
			})

			
			
			this.setState({ 
				dashData: result, 
				areaChartData: vendas30dias, 
				top10Produtos:top10Produtos,
				top10vendas:top10vendas,
				top10vendasValor:top10vendasValor,
				top10ProdutosValore:top10ProdutosValore,
				infoComparativo30Dias:infoComparativo30Dias,
				infoComparativo60Dias:infoComparativo60Dias,
				infoComparativoLabels:infoComparativoLabels
			})
		})
	}

    async componentDidMount() {
		const startDate = Moment().locale('pt-br')
        const endDate = Moment().locale('pt-br')
		const gap = endDate.diff(startDate, 'days')
		
		const currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		const prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}})

		if(this.context.stateImpressoras.length === 0){
			await this.context.atualizaImpressoras()
		}
		if(this.context.stateFilas.length === 0){
			await this.context.atualizaFilas()
		}

		this.fetchData(Moment().locale('pt-br').format('YYYY-MM-DD'), Moment().locale('pt-br').format('YYYY-MM-DD'))
	}

	handleDateApplyEvent(e, picker) {
		console.log(picker)

		var startDate = picker.startDate
		var endDate = picker.endDate
		var gap = endDate.diff(startDate, 'days')
		
		var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		var prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}})

		this.fetchData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
	}

	hadleFilterDate(e, filter){
		e.preventDefault()

		const today = Moment()
		let priorDate = Moment(new Date().setDate(new Date().getDate() - 1))

		this.setState({filterDates: filter})

		if (filter === "today"){
			this.handleDateApplyEvent(null, {startDate:today, endDate:today})
		}

		if (filter === "yesterday"){
			this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
		}

		if (filter === "lastweek"){
			priorDate = Moment(new Date().setDate(new Date().getDate() - 7))
			this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
		}

		if (filter === "last30"){
			priorDate = Moment(new Date().setDate(new Date().getDate() - 30))
			this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
		}

		if (filter === "thismonth"){
			priorDate = Moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
			this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
		}
	}

	modalConfigImpressora(e){
		e.preventDefault()

		this.setState( {modalConfigImpressora: !this.state.modalConfigImpressora} )
	}	

	render() {

		console.log(this.state.dashData)

		var dateRange = this.state.dateRange 

		this.donutChartOptions = {
			donut: true,
			showLegend: false,
			growOnHover: true,
			labelType: 'percent',
			margin: { 'left': 10,'right':  10,'top': 10,'bottom': 10 },
			donutRatio: 0.5,
			labelFormat: d3.format(',.0f')
		};
		this.donutChartData = [
			{ 'label': 'Delivery', 'value': this.state.dashData ? this.state.dashData.quantDelivery.length : 0, 'color': "#4806a3" }, 
			{ 'label': 'Mesas / Comandas', 'value': this.state.dashData ? this.state.dashData.quantDineIn.length : 0, 'color': "#0043ff" }
		];
		this.donutChartPagamentoData = [
			{ 'label': 'Dinheiro', 'value': this.state.dashData ? this.state.dashData.quantDinheiro : 0, 'color': "#0fc14a" }, 
			{ 'label': 'Credito', 'value': this.state.dashData ? this.state.dashData.quantCredito : 0, 'color': "#0043ff" },
			{ 'label': 'Debito', 'value': this.state.dashData ? this.state.dashData.quantDebito : 0, 'color': "#ff6f00" }, 
			{ 'label': 'Pix', 'value': this.state.dashData ? this.state.dashData.quantPix : 0, 'color': "#0095ff" }
		];

		<ul className="chart-legend fs-11px">
			<li><i className="fa fa-circle fa-fw text-green fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantDinheiro : 0} <span>Dinheiro</span></li>
			<li><i className="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantCredito : 0} <span>Crédito</span></li>
			<li><i className="fa fa-circle fa-fw text-warning fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantDebito : 0} <span>Débito</span></li>
			<li><i className="fa fa-circle fa-fw text-info fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantPix : 0} <span>PIX</span></li>
		</ul>

		this.label = function(d) {
			var monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']		
			d = new Date(d)
			var result = d.getDate()+" "+monthsName[d.getMonth()]+" - "+d.getHours()+':'+ d.getMinutes()

			if (dateRange && dateRange.gap && dateRange.gap > 0) {
				result = monthsName[d.getMonth()] + ' ' + d.getDate()
			} 
			
			return result
		}

		this.areaChartOptions = {
			pointSize: 0.5,
			useInteractiveGuideline: true,
			durection: 300,
			showControls: false,
			controlLabels: {
				stacked: 'Stacked'
			},
			
			xAxis: {
				tickFormat: this.label
			}
		};
		
		this.areaChartData = [{
			'values' : this.state.areaChartData
		}];

		this.globalOptions = {
			grid: {
		
			},
			title: {
				style: {
			
				}
			},
			legend: {
				labels: {
			
				}
			},
			xaxis: {
				axisBorder: {
					show: true,
				
					height: 1,
					width: '100%',
					offsetX: 0,
					offsetY: -1
				},
				axisTicks: {
					show: true,
					borderType: 'solid',
				
					height: 6,
					offsetX: 0,
					offsetY: 0
				},
				labels: {
					style: {
					
						fontWeight: 400,
						cssClass: 'apexcharts-xaxis-label',
					}
				}
			},
			yaxis: {
				labels: {
					style: {
					
						fontWeight: 400,
						cssClass: 'apexcharts-xaxis-label',
					}
				}
			}
		};

		this.chart = {
			lineChart: {
				options: {
					chart: {
						height: 350,
						type: 'line',
						toolbar: {
							show: false
						}
					},
					title: {
						text: 'Evolução de vendas',
						align: 'center',
						style: this.globalOptions.title.style
					},
					
					dataLabels: {
						enabled: true,
						background: {
							borderWidth: 0
						},
					},
					stroke: {
						curve: 'smooth',
						width: 3
					},
					grid: {
						row: {
							
							opacity: 0.5
						},
						borderColor: this.globalOptions.grid.borderColor
					},
					markers: {
						size: 4
					},
					xaxis: {
						categories: this.state.infoComparativoLabels,
						axisBorder: this.globalOptions.xaxis.axisBorder,
						axisTicks: this.globalOptions.xaxis.axisTicks,
						labels: this.globalOptions.xaxis.labels
					},
					yaxis: {
						labels: this.globalOptions.yaxis.labels
					},
					legend: {
						show: true,
						position: 'top',
						offsetY: -10,
						horizontalAlign: 'right',
						floating: true,
						labels: this.globalOptions.legend.labels
					}
				},
				series: [{
					name: 'Últimos 30 dias',
					data: this.state.infoComparativo30Dias
				}, {
					name: 'Anterior aos 30 dias',
					data: this.state.infoComparativo60Dias
				}]
			},
			columnChart: {
				options: {
					grid: this.globalOptions.grid,
					chart: {
						height: 350,
						type: 'bar',
						toolbar: {
							show: false
						}
					},
					title: {
						text: 'Top 10 produtos mais vendidos em quantidade',
						align: 'center',
						style: this.globalOptions.title.style
					},
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '55%',
							endingShape: 'rounded'	
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 2,
						colors: ['transparent']
					},
					
					xaxis: {
						categories: this.state.top10Produtos ? this.state.top10Produtos : [],
						axisBorder: this.globalOptions.xaxis.axisBorder,
						axisTicks: this.globalOptions.xaxis.axisTicks,
						labels: this.globalOptions.xaxis.labels
					},
					yaxis: {
						title: {
							text: 'Quantidade'
						},
						labels: this.globalOptions.yaxis.labels
					},
					fill: {
						opacity: 1
					},
					legend: this.globalOptions.legend,
					
				},
				series: [{
					name: 'Unidades',
					data: this.state.top10ProdutosValore ? this.state.top10ProdutosValore : []
				}]
			},
			columnChart10Vendidos: {
				options: {
					grid: this.globalOptions.grid,
					chart: {
						height: 350,
						type: 'bar',
						toolbar: {
							show: false
						}
					},
					title: {
						text: 'Top 10 produtos mais vendidos em vendas',
						align: 'center',
						style: this.globalOptions.title.style
					},
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '55%',
							endingShape: 'rounded'	
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 2,
						colors: ['transparent']
					},
					
					xaxis: {
						categories: this.state.top10vendas? this.state.top10vendas : [],
						axisBorder: this.globalOptions.xaxis.axisBorder,
						axisTicks: this.globalOptions.xaxis.axisTicks,
						labels: this.globalOptions.xaxis.labels
					},
					yaxis: {
						title: {
							text: 'Valores'
						},
						labels: this.globalOptions.yaxis.labels
					},
					fill: {
						opacity: 1
					},
					legend: this.globalOptions.legend,
					
				},
				series: [{
					name: 'Valor vendido',
					data:this.state.top10vendasValor ? this.state.top10vendasValor : []
				}]
			},
		}

		var initialDatePicker = {
          startDate: Moment().subtract(7, 'days'),
          endDate: Moment(),
          ranges: {
            'Hoje': [Moment().toDate(), Moment().toDate()],
            'Ontem': [
              Moment().subtract(1, 'days').toDate(),
              Moment().subtract(1, 'days').toDate(),
            ],
            'Últimos 7 dias': [
              Moment().subtract(6, 'days').toDate(),
              Moment().toDate(),
            ],
            'Últimos 30 Dias': [
              Moment().subtract(29, 'days').toDate(),
              Moment().toDate(),
            ],
            'Mês atual': [
              Moment().startOf('month').toDate(),
              Moment().endOf('month').toDate(),
            ],
            'Mês anterior': [
              Moment().subtract(1, 'month').startOf('month').toDate(),
              Moment().subtract(1, 'month').endOf('month').toDate(),
            ],
          },
        }

		if (this.state.isLoged) {
			return (
				this.state.user.perfil && this.state.user.loja ?
					<div>
						<div className="row">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">Home</li>
								<li className="breadcrumb-item active">Dashboard</li>
							</ol>
							<h1 className="page-header">
								<span style={{fontWeight:"normal"}}>Seja bem-vindo/a,</span> {this.state.nome_fantasia.toUpperCase()}
								<div className="widget" style={{padding: "10px", borderRadius: "10px", fontSize: "15px", float: "right"}}>
									<div className="stats-content">
										<div className="stats-title">
											<i className="fa-solid fa-link-simple"></i>
											Link do cardapio:&nbsp;
											<Link to={{pathname:"https://" + this.state.brand + ".grub.digital"}} target="_blank" >
												{"https://" + this.state.brand + ".grub.digital"}
											</Link>
										</div>
									</div>
								</div>
							</h1>
						</div>

						<div className="row">
							{/* <div className="col-xl-3 col-md-6" style={{}}>
								<Link to="/list/Produto">
									<div className="widget widget-stats" style={{height:'200px', width:'100%',textAlign: "center", backgroundImage: 'url(https://grubicons.s3.us-east-1.amazonaws.com/grub-produtos.png)', backgroundPosition: 'right center', backgroundSize:'cover'}}>
										<div className="stats-title" style={{color: "white", textDecoration: 'none', position:'absolute', bottom:'0', fontSize:"18px"}}>Lista de Produtos</div>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-md-6">
								<Link to="/list/Comanda">
									<div className="widget widget-stats" style={{height:'200px', width:'100%',textAlign: "center", backgroundImage: 'url(https://grubicons.s3.us-east-1.amazonaws.com/grub-pedidos.png)', backgroundPosition: 'right center', backgroundSize:'cover'}}>
										<div className="stats-title" style={{color: "white", textDecoration: 'none', position:'absolute', bottom:'0', fontSize:"18px"}}>Lista de Pedidos</div>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-md-6">
								<Link to="/pos/loja" >
									<div className="widget widget-stats" style={{height:'200px', width:'100%',textAlign: "center", backgroundImage: 'url(https://grubicons.s3.us-east-1.amazonaws.com/grub-ponto-de-venda.png)', backgroundPosition: 'right center', backgroundSize:'cover'}}>
										<div className="stats-title" style={{color: "white", textDecoration: 'none', position:'absolute', bottom:'0', fontSize:"18px"}}>Meu PDV</div>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-md-6">
								<Link to="/pos/kitchen" >
									<div className="widget widget-stats" style={{height:'200px', width:'100%',textAlign: "center", backgroundImage: 'url(https://grubicons.s3.us-east-1.amazonaws.com/grub-monitor.png)', backgroundPosition: 'right center', backgroundSize:'cover'}}>
										<div className="stats-title" style={{color: "white", textDecoration: 'none', position:'absolute', bottom:'0', fontSize:"18px"}}>Monitor de pedidos</div>
									</div>
								</Link>
							</div> */}
						</div>

						<div className='row'>
							<div style={{padding:'20px 0'}}>
								<div className="btn-group mb-3"  style={{float:"left"}}>
									<button className={this.state.filterDates === "today" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "today")}>Hoje</button>
									<button className={this.state.filterDates === "yesterday" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "yesterday")}>Ontem</button>
									<button className={this.state.filterDates === "lastweek" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "lastweek")}>Últ. 7 dias</button>
									<button className={this.state.filterDates === "last30" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "last30")}>Últ. 30 Dias</button>
									<button className={this.state.filterDates === "thismonth" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "thismonth")}>Mês atual</button>
								</div>

								<div className="d-sm-flex align-items-center mb-3" style={{float:"right"}}>
									<DateRangePicker initialSettings={initialDatePicker} onApply={this.handleDateApplyEvent}>
										<button className="btn btn-white me-2 text-truncate">
											<i className="fa fa-calendar fa-fw text-opacity-50 ms-n1 me-1"></i> 
											<span>{this.state.dateRange && (this.state.dateRange.currentWeek)}</span>
											<b className="caret ms-1 opacity-5"></b>
										</button>
									</DateRangePicker>
								</div>
							</div>
						</div>
						
						<div className="row">
							<div className="col-xl-3 col-md-6">
								<div className="widget widget-stats ">
									<div className="stats-content">
										<div className="stats-title">Faturamento Bruto</div>
										<div className="stats-number">{this.state.dashData ? this.state.dashData.faturamentoBruto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 0} </div>
										
										<div className="stats-desc">mes anterior  {this.state.dashData ? this.state.dashData.faturamentoBrutoMesAnterior.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 0}</div>
									</div>
								</div>
							</div>

							<div className="col-xl-3 col-md-6">
								<div className="widget widget-stats ">
									<div className="stats-content">
										<div className="stats-title">Pedidos</div>
										<div className="stats-number">{this.state.dashData ? this.state.dashData.totalComandas : 0} </div>
										
										<div className="stats-desc">mes anterior {this.state.dashData ? this.state.dashData.totalComandasMesAnterior : 0}</div>
									</div>
								</div>
							</div>

							<div className="col-xl-3 col-md-6">
								<div className="widget widget-stats ">
									<div className="stats-content">
										<div className="stats-title">Ticket Medio</div>
										<div className="stats-number"> {this.state.dashData ? this.state.dashData.ticketMedio.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 0} </div>
										
										<div className="stats-desc">mês anterior  {this.state.dashData ? this.state.dashData.ticketMedioMesAnterior.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 0}</div>
									</div>
								</div>
							</div>

							<div className="col-xl-3 col-md-6">
								<div className="widget widget-stats ">
									<div className="stats-content">
										<div className="stats-title">Taxa de serviço</div>
										<div className="stats-number">{this.state.dashData ? this.state.dashData.taxaServico.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 0}  </div>
										
										<div className="stats-desc">mês anterior {this.state.dashData ? this.state.dashData.taxaServicoMesAnterior.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 0}</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-xl-12">
								<div className="widget-chart">
									<div className="widget-chart-content ">
										<h4 className="chart-title">
											Vendas do período selecionado
											<small>Faturamento diário</small>
										</h4>
										<div className="widget-chart-full-width" style={{padding:'0 10px'}}>
											<NVD3Chart type="stackedAreaChart" datum={this.areaChartData} height={260} options={this.areaChartOptions} />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-xl-3">
								<div className="widget-chart">
									<div className="widget-chart-content ">
										<h4 className="chart-title">
											{this.state.dashData ? this.state.dashData.totalComandas : 0}&nbsp;
											<small>Total de vendas</small>
										</h4>
										<div className="flex-grow-1 d-flex align-items-center">
											<NVD3Chart type="pieChart" datum={this.donutChartData} height={180} options={this.donutChartOptions} x="label" y="value" />
										</div>
										<ul className="chart-legend fs-11px">
											<li><i className="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantDineIn.length : 0} <span>Mesas / Comandas</span></li>
											<li><i className="fa fa-circle fa-fw text-indigo fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantDelivery.length : 0} <span>Delivery</span></li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-xl-3">
								<div className="widget-chart">
									<div className="widget-chart-content ">
										<h4 className="chart-title">
											{this.state.dashData ? (this.state.dashData.quantDinheiro + this.state.dashData.quantCredito + this.state.dashData.quantDebito + this.state.dashData.quantPix) : 0}&nbsp;
											<small>Tipos de pagamento</small>
										</h4>
										<div className="flex-grow-1 d-flex align-items-center">
											<NVD3Chart type="pieChart" datum={this.donutChartPagamentoData} height={180} options={this.donutChartOptions} x="label" y="value" />
										</div>
										<ul className="chart-legend fs-11px">
											<li><i className="fa fa-circle fa-fw text-green fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantDinheiro : 0} <span>Dinheiro</span></li>
											<li><i className="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantCredito : 0} <span>Crédito</span></li>
											<li><i className="fa fa-circle fa-fw text-warning fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantDebito : 0} <span>Débito</span></li>
											<li><i className="fa fa-circle fa-fw text-info fs-9px me-5px t-minus-1"></i> {this.state.dashData ? this.state.dashData.quantPix : 0} <span>PIX</span></li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-xl-6">
								<div className="widget-chart">
									<div className="widget-chart-content ">
										<Chart type="bar" options={this.chart.columnChart10Vendidos.options} series={this.chart.columnChart10Vendidos.series} />
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-xl-6">
								<div className="widget-chart">
									<div className="widget-chart-content ">
										<Chart type="line" options={this.chart.lineChart.options} series={this.chart.lineChart.series} />
									</div>
								</div>
							</div>

							<div className="col-xl-6">
								<div className="widget-chart">
									<div className="widget-chart-content ">
										<Chart type="bar" options={this.chart.columnChart.options} series={this.chart.columnChart.series} />
									</div>
								</div>
							</div>
						</div>
						

						
					</div>
				:
					!this.state.user.loja?
						<Redirect to="/edit/Loja" />
					:
						<Redirect to="/login" />
			)
		} else {
			return <Redirect to="/login" />
		}
	}
}



export default Dashboard;
