import React from 'react'

import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'

import { saveObj, getList, getItem, removeOpcional } from '../../store/api.js'

export default class FormOpcionalSeletor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model:this.props.model,
            id:this.props.id,
            opcionaisOriginal: [],
            opcionaisVinculadosOriginal:[],
            opcionais: [],
            opcionaisVinculados:[],
            opcionaisBusca: [],
            opcionaisVinculadosBusca:[],
            ActiveMaximo: false,
            produto: {},
            max_opcional:0,
            min_opcional:0
        }

        this.setModelData = this.setModelData.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.filtro = this.filtro.bind(this)
        this.clearFiltro = this.clearFiltro.bind(this)
        this.setMax = this.setMax.bind(this)
        this.setMin = this.setMin.bind(this)
        this.handlerActiveMaximo = this.handlerActiveMaximo.bind(this)
    }

    fetchData(force = false){
		if (this.state.opcionais.length == 0 || force) {
            getItem(this.state.model, this.state.id).then((item) =>
                this.setState({ produto: item.data, ActiveMaximo: item.data.b_quantidade_opcional, min_opcional: item.data.min_opcional, max_opcional: item.data.max_opcional })
            )
            
            getItem('produto-opcional', this.state.id).then(resultvinculados => {
                this.setState({ opcionaisVinculados: resultvinculados.data })

                getList('Opcional').then(result => {
                    let filtrado = []

                    result.data.map((el) => {
                       const exist = resultvinculados.data.filter((r) => {return r.id == el.id})

                       if (exist.length == 0) filtrado.push(el)
                    })

                    this.setState({ opcionais: filtrado })
                })
            })
		}
	}

    filtro(e, vinculado = false){
        e.preventDefault()

        if (e.target && e.target.value.length > 0) {
            if (vinculado){
                this.setState({ opcionaisVinculadosBusca: this.state.opcionaisVinculados.filter(i => {return !i.nome.toLowerCase().search(e.target.value.toLowerCase())}) })
            } else {
                this.setState({ opcionaisBusca: this.state.opcionais.filter(i => {return !i.nome.toLowerCase().search(e.target.value.toLowerCase())}) })
            }
        } else {
            this.setState({ opcionaisBusca: [], opcionaisVinculadosBusca: []})
        }

    }

    clearFiltro(vinculado = false){
        if (vinculado){
            this.setState({ opcionaisVinculadosBusca: [] })
        } else {
            this.setState({ opcionaisBusca: [] })
        }
       
    }

    async handleDelete(event){
        event.preventDefault()

        await removeOpcional(event.target.dataset.obj, this.state.id)

        this.fetchData(true)
    }

    async handleAdd(event){
        event.preventDefault()

        const data = {items:{id_produto:this.props.id, id_opcional: [event.target.dataset.obj], tipo: "opcional"}, relations:{}}

        await saveObj(data, 'produto/opcional')

        this.fetchData(true)
    }

    async handlerMinMaxProduto(event) {
        event.preventDefault()

        const {action, objid} = event.target.dataset
        const data = {action: action, opcional: objid, product: this.props.id}

        await saveObj(data, 'produto/opcional/quantidade')

        getItem('produto-opcional', this.state.id).then(resultvinculados => {
            this.setState({ opcionaisVinculados: resultvinculados.data })
        })
    }

    async setMax(name, value){
        const data = {type: "opcional", action: "max", product: this.state.id, quantidade: value}

        await saveObj(data, 'produto/quantidade')

        this.setState({ max_opcional: value })
    }

    async setMin(name, value){
        const data = {type: "opcional", action: "min", product: this.state.id, quantidade: value}

        await saveObj(data, 'produto/quantidade')

        this.setState({ min_opcional: value})
    }
    
    async handlerActiveMaximo(event){
        event.preventDefault()

        const data = {type: "opcional", product: this.state.id}

        await saveObj(data, 'produto/active-quantidade')

        this.setState({ActiveMaximo: !this.state.ActiveMaximo})
    }

    async componentDidMount() {
        this.fetchData()
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    render() {
        const opcionais = this.state.opcionaisBusca.length > 0 ? this.state.opcionaisBusca : this.state.opcionais
        const opcionaisVinculados = this.state.opcionaisVinculadosBusca.length > 0 ? this.state.opcionaisVinculadosBusca : this.state.opcionaisVinculados

        return (
            <>
                <div className='row' style={{minHeight:'400px', position:'relative'}}>
                    <div className="col-md-6"  style={{position:'relative'}}>
                        <div className='col-md-12 m-15px'>
                            <p>No campo abaixo, pode procurar por opcionais pre-cadastrados e asocia-los a este produto, quando selecionar os desejados basta em clicar em "Incluir opcionais selecionados" para guardar as alterações.:</p>
                        </div>

                        <div style={{height:'300px', position: 'absolute', bottom: '0', width: '90%'}}>
                            <div className="input-group mb-3">
                                <div className="flex-fill position-relative">
                                    <div className="input-group">
                                        <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
                                            <i className="fa fa-search opacity-5"></i>
                                        </div>
                                        <input type="text" style={{margin:'0'}} className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.filtro(e, false)}/>
                                        <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 end-0" style={{zIndex: 10, cursor:'pointer'}} onClick={(e) => this.clearFiltro(false)}>
                                            <i className="fa fa-close opacity-5"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-list rounded" style={{height:'250px',overflowY:'scroll'}}>
                                {
                                    opcionais.length > 0 && opcionais.map(i =>  (
                                        <div className="widget-list-item">
                                            <div className="widget-list-media icon">
                                                <i className="fa fa-circle-check bg-gray text-white"></i>
                                            </div>
                                            <div className="widget-list-content">
                                                <h4 className="widget-list-title">{i.nome}</h4>
                                            </div>
                                            <div className="widget-list-action text-nowrap text-body text-opacity-50 fw-bold text-decoration-none">
                                                <a href="#" data-obj={i.id} onClick={(event) => this.handleAdd(event)} className="border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{cursor:'pointer'}}>Incluir</a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6" style={{position:'relative'}}>
                        <div className='col-md-12 m-15px'>
                            <h4>Opcionais disponiveis para este produto:</h4>
                        </div>
                        <div style={{height:'300px', position: 'absolute', bottom: '0', width: '90%'}}>
                            <div className="input-group mb-3">
                                <div className="flex-fill position-relative">
                                    <div className="input-group">
                                        <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
                                            <i className="fa fa-search opacity-5"></i>
                                        </div>
                                        <input type="text" style={{margin:'0'}} className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.filtro(e, true)}/>
                                        <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 end-0" style={{zIndex: 10, cursor:'pointer'}} onClick={(e) => this.clearFiltro(true)}>
                                            <i className="fa fa-close opacity-5"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-list rounded" style={{height:'250px',overflowY:'scroll'}}>
                                {
                                    opcionaisVinculados.length > 0 && opcionaisVinculados.map(i =>  (
                                        <div className="widget-list-item">
                                            <div className="widget-list-media icon">
                                                <i className="fa fa-circle-check bg-primary text-white"></i>
                                            </div>
                                            <div className="widget-list-content">
                                                <h4 className="widget-list-title">{i.nome}</h4>
                                            </div>
                                            <div className="widget-list-action text-nowrap text-body text-opacity-50 fw-bold text-decoration-none">
                                                <i data-obj={i.id} onClick={(event) => this.handleDelete(event)} className="fa fa-trash text-body text-opacity-30 fa-lg" style={{cursor:'pointer'}}></i>
                                            </div>

                                            <div className="widget-list-action text-nowrap text-body text-opacity-50 fw-bold text-decoration-none">
                                                <span data-action="menos" data-objid={i.id} onClick={(e) => this.handlerMinMaxProduto(e)} className='btn btn-white'><i className="fa-solid fa-minus"></i></span>
                                                <span className='btn btn-white'>{i.max}</span>
                                                <span data-action="mais" data-objid={i.id} onClick={(e) => this.handlerMinMaxProduto(e)} className='btn btn-white'><i className="fa-solid fa-plus"></i></span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row m-15px'>
                    <p>Adicione o minimo e maximo para os opcionais, caso nao seja ativado, o cliente poderá escolher quantos opcionais quiser</p>

                    <div className='col-md-12'>
                        <div className="form-check form-switch mb-2">
                            <input className="form-check-input form-check-input-pos" type="checkbox" onClick={(e) => this.handlerActiveMaximo(e)} checked={this.state.ActiveMaximo}/>
                            <label className="form-check-label" htmlFor="ativo_cardapio">Ativar minimo maximo</label>
                        </div>
                    </div>
                    
                    <div className='col-md-6'>

                        <div className="form-floating mb-15px" >
                            <InputText
                                nome="min"
                                setFomrData={this.setMin}
                                dadosIniciais={this.state.min_opcional}
                                
                            />
                            <Label nome="min" label="Quantidade Minima"/>
                        </div>

                    </div>
                    <div className='col-md-6'>

                        <div className="form-floating mb-15px" >
                            <InputText
                                nome="max"
                                setFomrData={this.setMax}
                                dadosIniciais={this.state.max_opcional}
                                
                            />
                            <Label nome="max" label="Quantidade Máxima"/>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}